import React, { useEffect, useState } from "react";
import Landingpage from "../component/Landingpage";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchApiGet, fetchApiPost } from "../utils/FetchApi";
import { ApiName } from "../utils/ApiName";
import tagManagerEvents from "../utils/GoogleTagManager";
import { heUrl, navigationUrl } from "../utils/config";
import { AppMessages } from "../utils/AppMessage";
import {
  getFlowService,
  getHeService,
  SendOtpService,
} from "../Services/SubscriptionServices";
import {
  IGetFlowNavigationData,
  IGetFlowResp,
  IHEApiResp,
  ISendOtpResp,
} from "../@types/ApiResponse";

type Props = {
  token: string | null;
};
const LandingPageScreen = (props: Props) => {
  const navigate = useNavigate();

  const [phoneNo, setPhoneNo] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [utmSource, setUtmSource] = useState<string | null>(null);

  useEffect(() => {
    if (props.token) {
      getHe();
    }
  }, [props.token]);

  const getHe = async () => {
    const response: IHEApiResp = await getHeService();
    try {
      if (response.code === "0" && response.msisdn) {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        const _utmSource: string | null = searchParams.get("utm_source");
        setUtmSource(_utmSource);
        getFlow(_utmSource, response.msisdn);
      } else {
        tagManagerEvents("wifiUser", utmSource);
        setErrorMsg(AppMessages.exclusiveUfoneServices);
      }
    } catch (error) {
      tagManagerEvents("wifiUser", utmSource);
      console.error("Error fetching data:", error);
      setErrorMsg(AppMessages.exclusiveUfoneServices);
    }
  };

  const getFlow = async (utmSource: string | null, msisdn: string) => {
    try {
      const response: IGetFlowResp | null = await getFlowService(
        props.token,
        utmSource,
        msisdn
      );
      if (response?.status === 0) {
        if (
          response.response.isSpecialFlow === true &&
          response.response.msisdn !== "" &&
          response.response.otp !== ""
        ) {
          // special flow
          const data: IGetFlowNavigationData = {
            isSpecialFlow: response.response.isSpecialFlow,
            fastFlow: response.response.fastFlow,
            msisdn: response.response.msisdn,
            otp: response.response.otp,
            milliseconds: response.response.milliseconds,
            signIn: response.response.signIn,
            package: response.response.subPackage,
            network: response.response.network,
            utmSource: utmSource,
          };

          navigate("/pin", { state: data });
        } else if (
          response.response.isSpecialFlow === false &&
          response.response.msisdn
        ) {
          // 4g user without special flow
          tagManagerEvents("heUser", utmSource);
          setPhoneNo(response.response.msisdn);
          if (response.response.signIn) {
            window.location.replace(navigationUrl);
          }
        } else {
          // wifi user
          setErrorMsg(AppMessages.exclusiveUfoneServices);

          tagManagerEvents("wifiUser", utmSource);
        }
      }
    } catch (error) {
      console.log("handleGetFlow Error: ", error);
    }
  };

  const handlePhoneNoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (!value || (value.length <= 10 && value.match(/^3\d{0,9}$/))) {
      setErrorMsg("");
      setPhoneNo(e.target.value);
    } else {
    }
  };

  const onSendOtpPressed = async () => {
    try {
      if (phoneNo) {
        setShowLoader(true);
        let response: ISendOtpResp = await SendOtpService(props.token, phoneNo);
        if (response?.status === 0) {
          const data: IGetFlowNavigationData = {
            msisdn: phoneNo,
            otp: response.response,
            utmSource: utmSource,
          };
          navigate("/pin", { state: data });
        } else {
          setErrorMsg(AppMessages.somethingWentWrong);
        }
        setShowLoader(false);
      } else {
        setErrorMsg(AppMessages.exclusiveUfoneServices);
      }
    } catch (error) {
      console.log("sendotp", error);
      setErrorMsg(AppMessages.somethingWentWrong);
    }
  };

  return (
    <Landingpage
      handlePhoneNoChange={handlePhoneNoChange}
      onSendOtpPressed={onSendOtpPressed}
      phoneNo={phoneNo}
      errorMsg={errorMsg}
      showLoader={showLoader}
    />
  );
};

export default LandingPageScreen;
