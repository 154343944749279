import React, { useEffect, useState } from "react";
import tagManagerEvents from "../utils/GoogleTagManager";
import AwarenessPage from "../component/AwarenessPage";
import { heUrl, navigationUrl } from "../utils/config";
import {
  ICheckUtmResp,
  IHEApiResp,
  ISubscribeResp,
} from "../@types/ApiResponse";
import {
  AwearnessServices,
  checkUtmService,
  getHeService,
} from "../Services/SubscriptionServices";

type Props = {
  token: string | null;
};
let adNetwork = "";

const AwarenessScreen = (props: Props) => {
  useEffect(() => {
    if (props.token) {
      getHe();
    }
  }, [props.token]);

  const getHe = async () => {
    const response: IHEApiResp = await getHeService();
    try {
      if (response.code === "0" && response.msisdn) {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        const _utmSource: string | null = searchParams.get("utm_source");
        if (_utmSource) {
          checkUtm(_utmSource);
        }
        getFlow(_utmSource, response.msisdn);
      } else {
        navigationURL();
      }
    } catch (error) {
      navigationURL();
    }
  };

  const checkUtm = async (utmSource: string | null) => {
    try {
      let response: ICheckUtmResp = await checkUtmService(props.token);
      console.log("UTM response", response);
      if (response.status === 0) {
        {
          response.response.map((e: any) => {
            if (utmSource === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const getFlow = async (utmSource: string | null, msisdn: string) => {
    try {
      let response: ISubscribeResp = await AwearnessServices(
        props.token,
        utmSource,
        msisdn
      );
      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          console.warn("aaa");
          tagManagerEvents("subscribe", "optimusTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          console.warn("bbb");
          tagManagerEvents("subscribe", "optimus");
        } else {
          console.warn("ccc");
          tagManagerEvents("subscribe", utmSource);
        }
        setTimeout(() => {
          navigationURL();
        }, 3000);
      } else {
        navigationURL();
      }
    } catch (error) {
      console.log(error);
      navigationURL();
    }
  };

  const navigationURL = () => {
    window.location.replace(navigationUrl);
  };
  return <AwarenessPage />;
};

export default AwarenessScreen;
