import React from "react";
import "../styles/LandingPage.css";
import logo from "../Assets/imgs/logo.webp";
import image from "../Assets/imgs/main-image.webp";
import trail from "../Assets/imgs/1_day_free.webp";

type Props = {
  onSendOtpPressed: () => void;
  handlePhoneNoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  phoneNo: string;
  errorMsg: string;
  showLoader: boolean;
};

const Landingpage = (props: Props) => {
  return (
    <div className="landingpage">
      <div className="main-content">
        <div className="logo">
          <img src={logo} width={153} height={71} alt="logo" />
        </div>
        <div className="main-img-wraper">
          <div className="image">
            <img src={image} width={340} height={295} alt="goldstar" />
          </div>
        </div>
        <div className="form">
          <div className="input-content">
            <div className="text">
              <img
                src={trail}
                width={187}
                height={34}
                alt="Ufone Cricket Fantasy"
              />
            </div>

            <div className="form-text">
              <p>Subscribe Just for Rs. 10 per day</p>
            </div>

            {props.errorMsg ? (
              <>
                <p className="invalid">{props.errorMsg}</p>
              </>
            ) : (
              <>
                {props.showLoader ? (
                  <div className="lp-loader">Loading...</div>
                ) : (
                  <button onClick={props.onSendOtpPressed}>TRY NOW</button>
                )}
              </>
            )}
          </div>

          <div className="sub-text-p">
            You can cancel your subscription at any time.
          </div>

          <div className="condition">
            <a href="/terms" target="_blank">
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
