import {
  ICheckUtmResp,
  IgenTokenApiResp,
  IGetFlowResp,
  IHEApiResp,
  ISendOtpResp,
  ISubscribeResp,
} from "../@types/ApiResponse";
import { ApiName } from "../utils/ApiName";
import { fetchApiGet, fetchApiPost } from "../Services/NetworkService";
import { username, password, heUrl } from "../utils/config";

export const GenerateTokenService = async () => {
  let data = {
    username: username,
    password: password,
  };
  const response: IgenTokenApiResp = await fetchApiPost(
    "",
    `${ApiName.generateToken}`,
    data
  );
  return response;
};

export const getHeService = async () => {
  const response = await fetch(heUrl);
  let jsonResponse: IHEApiResp = await response.json();
  return jsonResponse;
};

export const getFlowService = async (
  token: string | null,
  utmSource: string | null,
  msisdn: string
) => {
  let data = {
    utm_source: utmSource,
    msisdn: msisdn,
  };
  let response: IGetFlowResp = await fetchApiPost(token, ApiName.getFlow, data);
  return response;
};

export const SendOtpService = async (token: string | null, msisdn: string) => {
  let data = {
    msisdn: msisdn,
  };
  let response: ISendOtpResp = await fetchApiPost(token, ApiName.sendOtp, data);
  return response;
};

export const checkUtmService = async (token: string | null) => {
  let response: ICheckUtmResp = await fetchApiGet(token, ApiName.utmList);
  return response;
};

export const SubscribeUserService = async (
  token: string | null,
  endpoint: string,
  data: any
) => {
  let response = await fetchApiPost(token, endpoint, data);
  return response;
};

export const AwearnessServices = async (
  token: string | null,
  utmSource: string | null,
  msisdn: string
) => {
  let data = {
    utm_source: utmSource,
    msisdn: msisdn,
  };
  let response: ISubscribeResp = await fetchApiPost(
    token,
    ApiName.awarenessFlow,
    data
  );
  return response;
};
